import Sections from '@/components/cms/sections/sections';
import Page from '@/components/page';
import useGetRentalSearchHistory from '@/hooks/use-get-rental-search-history';
import useMe from '@/hooks/use-me';
import useTranslation from '@/hooks/use-translation';
import { getNavBarData } from '@/utils/strapi-cms/cms-utils';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import { Box, getTokenValue, useMedia } from '@drivekyte/ui';
import styled from 'styled-components';
import HeroSection from '../home-page/components/hero-section';
import { SearchbarToggleProvider } from '../home-page/components/nav-bar/desktop-nav-bar-search/search-bar-context';
import NavBar from '../home-page/components/nav-bar/nav-bar';
import { HomePageProps } from './home-page.types';
import MerchandisingUniversalTopBar from '@/components/experiment/merchandising-experiment';

const PageWrapper = styled.div`
  overflow: hidden;
`;

const HomePage = ({ data, componentData }: HomePageProps) => {
  const { t } = useTranslation('pages.home-page');
  const smallSpacing = getTokenValue('$small', 'space');
  const { me } = useMe();
  const media = useMedia();

  useGetRentalSearchHistory({
    uuid: me?.uuid,
    enabled: !!me?.uuid,
  });

  const navBarData = getNavBarData(data.navigationBar);

  return (
    <PageWrapper>
      <SearchbarToggleProvider
        isStickySearchBar={navBarData.fixedSearchOnScroll}
      >
        <MerchandisingUniversalTopBar />
        <NavBar navState={navBarData.variant} color={navBarData.color} />
      </SearchbarToggleProvider>
      <Page
        title={t('page-title')}
        top={{
          mobile: 0,
          web: !media.gtMd ? smallSpacing * 5 : 0,
        }}
        metaTags={{
          title: data.metadata?.metaTitle,
          'og:title': data.metadata?.metaTitle,
          description: data.metadata?.metaDescription,
          'og:description': data.metadata?.metaDescription,
        }}
      >
        <Box id="book-a-car-section">
          <HeroSection {...data.hero} description="" />
          <Box
            paddingVertical={`$${data.options?.mobileVerticalPadding}`}
            paddingHorizontal={`$${data.options?.mobileHorizontalPadding}`}
            $gtSm={{
              paddingHorizontal: `$${data.options?.desktopHorizontalPadding}`,
              paddingVertical: `$${data.options?.desktopVerticalPadding}`,
            }}
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            maxWidth={1440}
            width="100vw"
          >
            <ComponentDataContext.Provider value={componentData}>
              <Sections
                sections={data.modules}
                preview={false}
                gap={
                  media.gtSm
                    ? data.options?.desktopGap
                    : data.options?.mobileGap
                }
              />
            </ComponentDataContext.Provider>
          </Box>
        </Box>
      </Page>
    </PageWrapper>
  );
};

export default HomePage;
