import { useRouter } from 'next/router';
import request, { RequestError } from '@/utils/request';
import { PromoCode } from '@/types/quote';
import useMerchandisingStrategy from './use-merchandising-strategy';
import { useQuery } from 'react-query';

type StripePromoCodeResponse = {
  id: string;
  object: string;
  amount_off: number | null;
  created: number;
  currency: string | null;
  duration: string;
  duration_in_months: number;
  livemode: boolean;
  max_redemptions: number | null;
  metadata: Record<string, unknown>;
  name: string | null;
  percent_off: number | null;
  redeem_by: number | null;
  times_redeemed: number;
  valid: boolean;
};

export const getPromoCodeDataFromStripe = async (couponCode: string) => {
  const { data } = await request<StripePromoCodeResponse>({
    url: `/v1/coupons/${couponCode}`,
    baseURL: 'https://api.stripe.com',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_SECRET_STRIPE_KEY}`,
    },
    method: 'GET',
  });

  return {
    code: couponCode,
    percent_off: data?.percent_off || 0,
    value: data?.amount_off || 0,
  };
};

const usePromoCodeData = () => {
  const router = useRouter();
  const { isMerchandisingStrategyExperiment } = useMerchandisingStrategy();

  return useQuery<PromoCode, RequestError>(
    'promo-code',
    () => getPromoCodeDataFromStripe(router.query.code as string),
    { enabled: !!router.query.code && !!isMerchandisingStrategyExperiment },
  );
};

export default usePromoCodeData;
