import React from 'react';
import { Box, Typography } from '@drivekyte/ui';
import useTranslation from '@/hooks/use-translation';
import { useRouter } from 'next/router';
import usePromocodeToLoadCodedata from '@/hooks/experiments/use-merchandising-strategy/use-promocode-to-load-codedata';
import { ShoppingTag } from '@drivekyte/icons';

const MerchandisingUniversalTopBar = () => {
  const { t } = useTranslation('pages.car-picker');
  const {
    data: promoCodeData,
    isLoading,
    error,
  } = usePromocodeToLoadCodedata();
  const router = useRouter();

  if (!promoCodeData || isLoading || error) {
    // needed so we have no layout shift on homepage and cms pages
    const bgColor = !!router.query?.slug ? '' : '$backgroundPositiveActive';

    return (
      <Box
        paddingVertical="$medium"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={bgColor}
        minHeight="42px"
      />
    );
  }

  return (
    <Box>
      <Box
        paddingVertical="$xSmall"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="$backgroundPositiveSubtle"
        flexDirection="row"
      >
        <ShoppingTag width="12" height="12" color="black" />
        <Typography
          variant="paragraphSmall"
          color="$contentPrimary"
          fontWeight="bold"
        >
          {t('discount-applied', {
            value: promoCodeData?.percent_off,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default MerchandisingUniversalTopBar;
